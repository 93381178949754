import {getCurrentInstance,reactive,toRefs,defineComponent} from 'vue';
export default defineComponent({
    name: "notExist",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let store = proxy.$store;
        let dataObj=reactive({

        })
        const lockLoginOut=async()=>{
            proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            //退出登录的时候，只需要把userInfo去掉，不能把token去掉，否则报错
            sessionStorage.removeItem("userInfo");
            let res = await utils.Api.loginOut();
            if(res.result){
                utils.Tools.success({message: proxy.$t('login.loginOutMsg')});
                location.reload();
            }else{
                utils.Tools.error();
            }
        }
        return{
            ...toRefs(dataObj),lockLoginOut
        }
    }
})